import React from "react"
import {Link, graphql} from "gatsby";
import styled from "@emotion/styled";

import {ICaseStudyFeature, IService} from "../utils/types";
import Layout from "../components/layout/layout";
import Hero from "../components/home/hero";
import FootQuote from "../components/home/footQuote";

export default ({ data }) => {
    return (
        <Layout title="404">
            <Hero
                tagline="Page not found"
                taglineLine2=""
                taglineUnderline=""
                subheadDescription="" />
        </Layout>
    );
}

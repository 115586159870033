import React from "react";
import styled from "@emotion/styled";

import Container from "../container";
import Underliner from "../underliner";
import {md} from "../../utils/breakpoints";

const SubheadContainer = styled.h2<any>`
    font-size: 24px;
    font-weight: 400;
    font-family: ${props => props.theme.contentFontFamily};
    color: ${props => props.theme.secondaryColor};
    max-width: 850px;
    margin: 100px auto 20px;
    text-align: center;
    display: block;
    width: 100%;
    line-height: 1.4;

    @media (max-width: ${md}px) {
        font-size: 18px;
    }
`;

const TaglineContainer = styled.h1<any>`
    font-size: 48px;
    font-weight: 400;
    color: ${props => props.theme.primaryColor};
    display: block;
    max-width: 850px;
    margin: 0px auto;
    text-align: center;
    line-height: 1.4;

    @media (max-width: ${md}px) {
        font-size: 30px;
    }
`;

const Seperator = styled.div<any>`
    margin: 100px auto;
    width: 140px;
    height: 1px;
    background: ${props => props.theme.borderColor};

    @media (max-width: ${md}px) {
        margin: 50px auto;
    }
`;

export interface IHeroProps {
    tagline: string;
    taglineLine2: string;
    taglineUnderline: string;
    subheadDescription: string;
}

export default function Hero(props: IHeroProps): JSX.Element {
    const {tagline, taglineLine2, taglineUnderline, subheadDescription} = props;

    return (
        <Container>
            <SubheadContainer>{subheadDescription}</SubheadContainer>
            <TaglineContainer>
                <Underliner text={tagline} underline={taglineUnderline} />
                <br/>
                <Underliner text={taglineLine2} underline={taglineUnderline} />
            </TaglineContainer>
            <Seperator />
        </Container>
    );
}